<template>
  <v-container fluid class="main-container">
    <v-container>
      <v-row class="d-flex flex-column align-center">
        <v-col cols="12" md="8" class="">
          <v-card>
            <v-row dense>
              <v-col cols="12">
                <v-img src="@/assets/images/bannerParcelRules.png"></v-img>
              </v-col>
            </v-row>
            <!-- ppal -->
            <v-container
              fluid
              class="pa-4 fontUrbanist mt-3"
              style="border-top: solid 4px #ef4370"
            >
              <v-row dense class="pb-2">
                <v-col cols="12" class="d-flex justify-center">
                  <h2>Ridery - Envíos</h2>
                </v-col>
              </v-row>
              <v-row
                dense
                class="d-flex flex-row align-center pt-2 pb-2 borders-row"
              >
                <v-col
                  cols="12"
                  class="font-weight-bold d-flex justify-center text-xl"
                >
                  Dimensiones y Pesos Permitidos
                </v-col>
              </v-row>
              <v-row
                dense
                class="d-flex flex-row align-center pt-2 pb-2 borders-row"
              >
                <v-col
                  cols="12"
                  md="4"
                  class="
                    font-weight-bold
                    d-flex
                    flex-column
                    justify-center
                    align-center
                    text-xl
                  "
                >
                  <v-img src="@/assets/parcel/motorider (1).png" max-width="150">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="primary"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  Envíos Moto
                </v-col>
                <v-col cols="12" md="8">
                  <v-row dense>
                    <v-col cols="12">
                      <h4>Envíos Moto:</h4>
                    </v-col>
                  </v-row>
                  <br />
                  <v-row dense>
                    <v-col cols="6">
                      <h4>Tamaño del paquete:</h4>
                      <p>36 cm x 33 cm x 22 cm</p>
                    </v-col>
                    <v-col cols="6">
                      <h4>Kilos permitidos:</h4>
                      <p>4 Kg máx</p>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12">
                      <h4>Puedes enviar en este tipo de paquetes:</h4>
                      <p>
                        Resma de papel / repuestos pequeños / casco de moto /
                        llaves.
                      </p>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row
                dense
                class="d-flex flex-row align-center pt-2 pb-2 borders-row"
              >
                <v-col
                  cols="12"
                  md="4"
                  class="
                    font-weight-bold
                    d-flex
                    flex-column
                    justify-center
                    align-center
                    text-xl
                  "
                >
                  <v-img src="@/assets/parcel/economico (1).png" max-width="150" contain>
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="primary"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  Envíos Económico
                </v-col>
                <v-col md="8" cols="12">
                  <v-row dense>
                    <v-col cols="12">
                      <h4>Envíos Económico:</h4>
                    </v-col>
                  </v-row>
                  <br />
                  <v-row dense>
                    <v-col cols="6">
                      <h4>Tamaño del paquete:</h4>
                      <p>69 cm x 44 cm x 26 cm</p>
                    </v-col>
                    <v-col cols="6">
                      <h4>Kilos permitidos:</h4>
                      <p>40 Kg máx</p>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12">
                      <h4>Puedes enviar en este tipo de paquetes:</h4>
                      <p>
                        1 equipaje mediano + morral pequeño / 7 bolsas de
                        supermercado/ cava pequeña / 1 caja de harina de maíz de
                        20 empaques.
                      </p>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row
                dense
                class="d-flex flex-row align-center pt-2 pb-2 borders-row"
              >
                <v-col
                  cols="12"
                  md="4"
                  class="
                    font-weight-bold
                    d-flex
                    flex-column
                    justify-center
                    align-center
                    text-xl
                  "
                >
                  <v-img src="@/assets/parcel/ridery (1).png" max-width="150" contain>
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="primary"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  Envíos Ridery
                </v-col>
                <v-col md="8" cols="12">
                  <v-row dense>
                    <v-col cols="12">
                      <h4>Envíos Ridery:</h4>
                    </v-col>
                  </v-row>
                  <br />
                  <v-row dense>
                    <v-col cols="6">
                      <h4>Tamaño del paquete:</h4>
                      <p>88 cm x 69 cm x 26 cm</p>
                    </v-col>
                    <v-col cols="6">
                      <h4>Kilos permitidos:</h4>
                      <p>60 Kg máx</p>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12">
                      <h4>Puedes enviar en este tipo de paquetes:</h4>
                      <p>
                        2 equipajes medianos + morral pequeño / 9 bolsas de
                        supermercado / cava mediana / 2 cajas de harina de maíz
                        de 20 empaques.
                      </p>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row
                dense
                class="d-flex flex-row align-center pt-2 pb-2 borders-row"
              >
                <v-col
                  cols="12"
                  md="4"
                  class="
                    font-weight-bold
                    d-flex
                    flex-column
                    justify-center
                    align-center
                    text-xl
                  "
                >
                  <v-img src="@/assets/parcel/riderymax (2).png" max-width="150" contain>
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="primary"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  Envíos Ridery Max
                </v-col>
                <v-col md="8" cols="12">
                  <v-row dense>
                    <v-col cols="12">
                      <h4>Envíos Ridery Max:</h4>
                    </v-col>
                  </v-row>
                  <br />
                  <v-row dense>
                    <v-col cols="6">
                      <h4>Tamaño del paquete:</h4>
                      <p>70 cm x 45 cm x 30 cm</p>
                    </v-col>
                    <v-col cols="6">
                      <h4>Kilos permitidos:</h4>
                      <p>100 Kg máx</p>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12">
                      <h4>Puedes enviar en este tipo de paquetes:</h4>
                      <p>
                        2 equipajes grandes / 20 bolsas de supermercado/ 3 cavas
                        medianas.
                      </p>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row
                dense
                class="d-flex flex-row align-center pt-2 pb-2 borders-row"
              >
                <v-col
                  cols="12"
                  md="4"
                  class="
                    font-weight-bold
                    d-flex
                    flex-column
                    justify-center
                    align-center
                    text-xl
                  "
                >
                  <v-img src="@/assets/parcel/rideryPICKUP--2 (1).png" max-width="150" contain>
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="primary"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  Envíos Pick Up
                </v-col>
                <v-col md="8" cols="12">
                  <v-row dense>
                    <v-col cols="12">
                      <h4>Envíos Pick Up:</h4>
                    </v-col>
                  </v-row>
                  <br />
                  <v-row dense>
                    <v-col cols="6">
                      <h4>Tamaño del paquete:</h4>
                      <p>120 cm x 100 cm x 75 cm</p>
                    </v-col>
                    <v-col cols="6">
                      <h4>Kilos permitidos:</h4>
                      <p>120 Kg máx</p>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12">
                      <h4>Puedes enviar en este tipo de paquetes:</h4>
                      <p>4 equipajes grandes / 5 cajas medianas</p>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row
                dense
                class="d-flex flex-row align-center pt-2 pb-2 borders-row"
              >
                <v-col
                  cols="12"
                  md="4"
                  class="font-weight-bold d-flex justify-center text-xl"
                >
                  Condiciones
                </v-col>
                <v-col
                  md="8"
                  cols="12"
                  class="d-flex flex-column justify-center align-center"
                >
                  <ul>
                    <li>
                      El cliente deberá establecer un único responsable
                      (Usuario) del Servicio, sin excepción, a quién se le podrá
                      contactar en caso de cualquier imprevisto o necesidad de
                      información.
                    </li>
                    <li>
                      La recogida y entrega de la encomienda se realiza en la
                      acera o entrada principal del Punto A y Punto B designado
                      por el Usuario.
                    </li>
                    <li>
                      El Usuario debe asegurar que la encomienda esté embalada o
                      cubierta para garantizar la integridad del ítem enviado.
                    </li>
                    <li>
                      El Rider puede cancelar la orden de servicio si la
                      encomienda declarada no coincide con las características
                      permitidas.
                    </li>
                    <li>
                      Las encomiendas deben entrar cómodamente en las maletas de
                      los vehículos y permitir que cierren.
                    </li>
                    <li>
                      Solo las maletas de los vehículos se consideran
                      contenedores autorizados para trasladar encomiendas.
                    </li>
                  </ul>
                </v-col>
              </v-row>
              <v-row
                dense
                class="d-flex flex-row align-center pt-2 pb-2 borders-row"
              >
                <v-col
                  cols="12"
                  md="4"
                  class="font-weight-bold d-flex justify-center text-xl"
                >
                  Entrega Fallidas
                </v-col>
                <v-col
                  cols="12"
                  md="8"
                  class="d-flex flex-column justify-center align-center"
                >
                  <p>
                    En aquellos casos en los cuales las entregas no pudieran ser
                    completadas por el Rider, por causas ajenas a este,
                    entendiendo como tal aquellos casos en los cuales no pueda
                    comunicarse o ubicar al receptor del paquete, este deberá
                    comunicarse con el usuario que solicitó el Servicio y
                    ofrecerle cualquiera de las siguientes soluciones:
                  </p>
                  <ul>
                    <li>
                      Coordinar el traslado del paquete al punto de recogida del
                      Servicio, con la finalidad de entregar al usuario que
                      solicitó el Servicio el paquete que hubiere sido enviado.
                    </li>
                    <li>
                      Notificar al usuario que su paquete será trasladado hasta
                      las oficinas centrales de
                      <span class="font-weight-bold">Ridery</span>, mediante la
                      coordinación, en conjunto con el equipo de soporte de
                      <span class="font-weight-bold">Ridery</span>, de un viaje
                      hasta ellas, donde el paquete será resguardado hasta que
                      el usuario y
                      <span class="font-weight-bold">Ridery</span> puedan
                      solucionar la situación y enviar el paquete hasta el punto
                      de destino.
                    </li>
                    <li>
                      En caso de que el Rider, luego de múltiples llamadas y el
                      transcurso de al menos de veinte (20) minutos en que se
                      materializó la falta de respuesta por parte del receptor,
                      no pueda comunicarse con el usuario que solicitó el
                      Servicio para explicarle la situación y plantearle las
                      soluciones, procederá a trasladar el paquete a las
                      oficinas centrales de
                      <span class="font-weight-bold">Ridery</span> para el
                      resguardo del paquete.
                    </li>
                  </ul>
                  <br />
                  <p>
                    El usuario entiende que en cualquiera de los casos descritos
                    anteriormente serán generados recargos, sobre lo previamente
                    pagado, por los nuevos traslados que tenga que realizar el
                    Rider para realizar la devolución del paquete.
                  </p>
                </v-col>
              </v-row>
              <v-row
                dense
                class="d-flex flex-row align-center pt-2 pb-2 borders-row"
              >
                <v-col
                  cols="12"
                  md="4"
                  class="font-weight-bold d-flex justify-center text-xl"
                >
                  El Servicio No Incluye
                </v-col>
                <v-col
                  cols="12"
                  md="8"
                  class="d-flex flex-column justify-center align-center"
                >
                  <ul>
                    <li>
                      No incluye encomiendas de mayor peso o dimensión a las
                      descrita en cada categoría, es decir, no garantizamos que
                      el servicio será aceptado si la encomienda excede las
                      siguientes características:
                      <ul>
                        <li>
                          Envío Moto - dimensiones: 36 cm x 33 cm x 26 cm - 4
                          Kg Máx.
                        </li>
                        <li>
                          Envío Económico - dimensiones: 69 cm x 44 cm x 26 cm
                          40 Kg Máx.
                        </li>
                        <li>
                          Envío Ridery – dimensiones: 88 cm x 69 cm x 26 cm 60
                          Kg Máx.
                        </li>
                        <li>
                          Envío Ridery Max: – dimensiones: 70 cm x 45 cm x 30 cm 100 Kg Máx.
                        </li>
                        <li>
                          Envío Pick Up – dimensiones: 120 cm x 100 cm x 75 cm 120
                          Kg Máx.
                        </li>
                      </ul>
                    </li>
                    <li>
                      No está garantizada la integridad de envíos que requieren
                      de cuidados especiales tales como, pinturas, manualidades,
                      esculturas, repostería o productos que ameriten
                      refrigeración, haciendo uso del Servicio, ninguna de las
                      partes implicadas garantiza la integridad del paquete, por
                      lo cual el usuario entiende que de enviar este tipo de
                      paquetes lo hace bajo su propio riesgo.
                    </li>
                    <li>
                      No incluye servicio de asistencia de recogida dentro de
                      inmuebles comerciales o residenciales o que requiera que
                      el conductor (Rider) asuma el costo de un estacionamiento
                      privado. Tampoco que manipule cargas múltiples bajando,
                      subiendo pisos o necesite herramientas como carretillas.
                    </li>
                    <li>No se ofrece cobertura mayor a $100 por encomienda.</li>
                    <li>
                      El traslado de encomiendas en los asientos del vehículo,
                      en particular si el paquete tiene características filosas
                      o contiene líquidos que puedan derramarse.
                    </li>
                    <li>No trasladamos mascotas.</li>
                    <li>
                      No se realizan entregas entre estados Ej. Caracas
                      -Valencia.
                    </li>
                    <li>Cargo.</li>
                  </ul>
                </v-col>
              </v-row>
              <v-row
                dense
                class="d-flex flex-row align-center pt-2 pb-2 borders-row"
              >
                <v-col
                  cols="12"
                  md="4"
                  class="font-weight-bold d-flex justify-center text-xl"
                >
                  Servicio de Alternancia
                </v-col>
                <v-col
                  cols="12"
                  md="8"
                  class="d-flex flex-column justify-center align-center"
                >
                  <p>
                    Dinámica de alternancia de servicios, no está permitido
                    realizar servicios en simultáneo, el Rider puede brindar
                    tanto servicio de viajes, como de encomiendas, pero solo
                    debe realizar uno a la vez.
                  </p>
                </v-col>
              </v-row>
              <v-row
                dense
                class="d-flex flex-row align-center pt-2 pb-2 borders-row"
              >
                <v-col
                  cols="12"
                  md="4"
                  class="font-weight-bold d-flex justify-center text-xl"
                >
                  Póliza de Seguros
                </v-col>
                <v-col
                  cols="12"
                  md="8"
                  class="d-flex flex-column justify-center align-center"
                >
                  <p class="mt-4">
                    Las encomiendas están protegidas hasta un monto máximo de
                    $100.
                  </p>
                </v-col>
              </v-row>
              <v-row
                dense
                class="d-flex flex-row align-center pt-2 pb-2 borders-row"
              >
                <v-col
                  cols="12"
                  md="4"
                  class="font-weight-bold d-flex justify-center text-xl"
                >
                  Objetos Inaceptables<br />
                  o Prohibidos
                </v-col>
                <v-col
                  cols="12"
                  md="8"
                  class="d-flex flex-column justify-center align-center"
                >
                  <p>
                    El usuario entiende y acepta que los artículos y bienes
                    incluidos en el listado presentado a continuación no podrán,
                    bajo ningún concepto, ser enviados mediante el Servicio:
                  </p>
                  <ul>
                    <li>
                      Dinero, en cualquiera que sea su presentación, metálico,
                      cheques, entre otros. Se incluyen títulos valores,
                      elementos de joyería, tarjetas bancarias y afines.
                    </li>
                    <li>
                      Drogas en general, estupefacientes, narcóticos y cualquier
                      artículo afín a estos.
                    </li>
                    <li>
                      Armas, bien sea de fuego o armas blancas, municiones y
                      cualquier otro artículo que pudiera ser considerado como
                      armamento.
                    </li>
                    <li>
                      Explosivos o inflamables, sin importar su naturaleza.
                    </li>
                    <li>Fuegos artificiales.</li>
                    <li>
                      Materiales que sean químicos o biológicos y artículos que
                      representen riesgo biológico en general.
                    </li>
                    <li>
                      Animales, vivos o muertos, de cualquier tipo, aún cuando
                      estén adiestrados.
                    </li>
                    <li>Muestras y materiales médicos.</li>
                    <li>
                      Sustancias corrosivas y químicas en general. De igual
                      manera cualquier sustancia tóxica.
                    </li>
                    <li>Materiales radiactivos.</li>
                  </ul>
                  <br />
                  <p>
                    El usuario entiende que aceptando estas Condiciones se
                    compromete a no realizar envíos de paquetes que contengan
                    uno o varios de los artículos descritos en el listado
                    previamente expuesto, entendiendo que de hacerlo estaría
                    violando lo establecido en estás condiciones, por lo que
                    <span class="font-weight-bold">Ridery</span>, en caso de
                    conocer del envío de alguno de estos artículos, cancelará la
                    solicitud del Servicio al usuario. El usuario acepta y
                    reconoce que en el supuesto de que realizara el envío de
                    alguno de estos artículos de forma tal que
                    <span class="font-weight-bold">Ridery</span> no pudiera
                    determinar su existencia, será el único responsable por los
                    daños que pueda causar dicha situación, así como las
                    consecuencias que pueda generar para
                    <span class="font-weight-bold">Ridery</span> o los
                    conductores asociados a la Plataforma.
                  </p>
                  <p>
                    <span class="font-weight-bold">Ridery</span> se reserva en
                    todo momento el derecho de admisión de un envío y
                    consecuentemente de prestar un servicio, tanto por parecerle
                    anómalo su contenido, como por las circunstancias extrañas
                    del encargo. Queda prohibida la entrega / recogida de envíos
                    cuyo contenido sea contrario a la ley moral o buenas
                    costumbres, o de tráfico ilícito, incurriendo el cliente en
                    las correspondientes responsabilidades, y quedando
                    <span class="font-weight-bold">Ridery</span>
                    totalmente exonerada de las mismas.
                  </p>
                  <p>
                    En aquellos casos en los cuales el usuario hiciere uso del
                    Servicio de Envíos y Encomiendas para trasladar un paquete
                    que fuere contrario a lo establecido en la ley, los términos
                    y condiciones de
                    <span class="font-weight-bold">Ridery</span> y/o del
                    Servicio o estas condiciones de uso, deberá indemnizar a
                    <span class="font-weight-bold">Ridery</span> por los daños y
                    perjuicios que pudiere ocasionar este uso incorrecto del
                    Servicio, así como cualquier gasto en el cual tuviera que
                    incurrir <span class="font-weight-bold">Ridery</span> para
                    establecer su desconocimiento e inocencia en el conocimiento
                    de los artículos que componen el paquete.
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.main-container {
  background-color: #f4f5fa !important;
  min-height: 100vh;
}
.borders-row {
  border-top: solid 1px #3bd4ae;
}
</style>
